export const UNLOCK_GAME = 'UNLOCK_GAME';
export const UNLOCK_SSM = 'UNLOCK_SSM';
export const SET_SELECTED_TAB = 'SET_SELECTED_TAB';
export const SET_ANSWER = 'SET_ANSWER';
export const SET_QUIZ_STAGE = 'SET_QUIZ_STAGE';
export const SET_ISBN_PART = 'SET_ISBN_PART';

export const TAB_A = 'TAB_A';
export const TAB_B = 'TAB_B';
export const TAB_C = 'TAB_C';
export const TAB_D = 'TAB_D';
